<template>
  <v-container class="container-spacing">
    <h6 class="headline font-weight-semibold text-center">
      {{ $t("Your Benefits") }}
    </h6>
    <p
      class="subtitle-1 dark--text mt-4 mb-lg-3 mb-2 text-center mx-auto"
      :style="
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' : 'max-width: 50%'
      "
    >
      {{ $t("Welcome to") }}
      <span class="dark--text font-weight-bold">{{ companyName }}</span>
      {{ $t("’s behavioural health program provided by TalkTime!") }}
    </p>
    <v-row class="mt-10">
      <v-col cols="12" md="5" class="mx-auto d-flex flex-column">
        <v-card
          class="rounded-lg text-start d-flex flex-column fill-height py-4"
          elevation="2"
        >
          <div class="px-8 py-3 has-border-bottom">
            <h4 style="font-size: 18px">{{ $t("Your Benefits") }}</h4>
          </div>

          <div class="d-flex flex-column fill-height">
            <v-card-text class="px-8 pb-0">
              <v-list class="pt-0">
                <v-list-item
                  dense
                  v-for="feature in individual"
                  class="px-0 font-weight-semibold"
                  :key="feature.name"
                  :class="feature.included ? '' : 'muted--text'"
                >
                  <img
                    v-if="feature.included"
                    src="@/assets/check-primary.svg"
                    class="me-2"
                    width="20"
                  />
                  <img
                    v-else
                    src="@/assets/close-primary.svg"
                    class="me-2"
                    width="20"
                  />
                  {{ feature.name }}
                </v-list-item>
              </v-list>
            </v-card-text>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="5" class="mx-auto d-flex flex-column">
        <v-list class="bg-transparent">
          <v-list-item>
            <SuccessSvg class="me-4" />

            <v-list-item-content>
              <h4 class="font-weight-normal lh-halfOne opacity-75">
                {{ $t("You have") }} {{ liveSessionCountWeek }}
                {{ $t(monthOrWeekOrYear) }}
                {{ companyName }}.
              </h4>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <PricingSvg class="me-4" />

            <v-list-item-content>
              <h4 class="font-weight-normal lh-halfOne opacity-75">
                {{
                  $t(
                    "Additional sessions can be purchased for AED 450 per session."
                  )
                }}
              </h4>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn
          :ripple="false"
          color="primary"
          class="py-3 mt-auto rounded-15px"
          :loading="loading"
          @click="goNextStep"
        >
          {{ $t("Get Started") }}
          <v-icon right>{{
            $vuetify.rtl ? "mdi-arrow-right" : "mdi-arrow-right "
          }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SuccessSvg from "@/components/svg/SuccessSvg";
import PricingSvg from "@/components/svg/PricingSvg";
import { mapState } from "vuex";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SuccessSvg,
    PricingSvg,
  },

  computed: {
    ...mapState("preRegister", ["promoCodeData"]),

    companyName() {
      return this.promoCodeData?.company?.name;
    },

    liveSessionCountWeek() {
      return this.promoCodeData?.corporatePlan?.liveSessionAdd;
    },

    week() {
      return this.promoCodeData?.corporatePlan?.recurringPeriod;
    },

    type() {
      return this.promoCodeData?.corporatePlan?.type;
    },

    monthOrWeekOrYear() {
      if (this.type === "Recurring") {
        let weeks = this.week;
        if (weeks % 4 === 0) {
          if (weeks === 4) {
            return this.$vuetify.rtl
              ? "جلسات مجانية كل شهر مغطاة من قبل"
              : "free sessions per month covered by";
          } else if (weeks > 4 && weeks < 52) {
            return this.$vuetify.rtl
              ? "جلسات مجانية كل شهر" + " " + weeks / 4 + " " + "مغطاة من قبل"
              : "free sessions per" +
                  " " +
                  weeks / 4 +
                  " " +
                  "months covered by";
          } else if (weeks === 52) {
            return this.$vuetify.rtl
              ? "جلسات مجانية سنوياً مغطاة من قبل"
              : "free sessions per year covered by";
          }
        } else {
          if (weeks === 1) {
            return this.$vuetify.rtl
              ? "جلسات مجانية أسبوعياً مغطاة من قبل"
              : "free sessions per week covered by";
          } else {
            return this.$vuetify.rtl
              ? "جلسات مجانية لكل أسبوع" + " " + weeks + " " + "مغطاة من قبل"
              : "free sessions per" + " " + weeks + " " + "weeks covered by";
          }
        }
        return "";
      } else {
        return this.$vuetify.rtl
          ? "جلسات مجانية مغطاة من قبل"
          : "free sessions covered by";
      }
    },

    individual() {
      // {
      //   name: this.$t("20min Free Initial Consultation"),
      //   included: !!this.promoCodeData?.corporatePlan?.freeLiveSessionAdd,
      // },
      return [
        {
          name: this.$t("50min Live Session"),
          included: !!this.promoCodeData?.corporatePlan?.liveSessionAdd,
        },
        {
          name: this.$t("Future-Self Journal"),
          included: true,
        },
        {
          name: this.$t("Chat Messaging"),
          included: true,
        },
      ];
    },
  },

  methods: {
    goNextStep() {
      this.$emit("success");
    },
  },
};
</script>
