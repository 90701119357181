<template>
  <div class="hp-body-bg fill-height">
    <NoUserNavbar/>
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" md="8" lg="6" class="mx-auto">
          <InsuranceForm @continueInsurance="saveChangedInsuranceData" :loading="loading" :insuranceData.sync="insuranceData"/>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

import InsuranceForm from "@/components/Admin/CorporateProgram/InsuranceForm.vue";
import NoUserNavbar from "@/components/layout/NoUserNavbar.vue";
import UserMixin from "@/mixins/UserMixin";
import {mapActions} from "vuex";

export default {
  name: "InsuranceFormInner",
  components: {
    NoUserNavbar,
    InsuranceForm,

  },

  mixins: [UserMixin],

  data: () => {
    return {
      loading: false,
      insuranceData: {
        insurancePolicyNumber: '',
        firstName: '',
        lastName: '',
        birthDate: '',
      },
    }
  },

  methods: {
    ...mapActions('user', ['updateInsuranceUserData']),


    async saveChangedInsuranceData() {


      this.loading = true

      await this.updateInsuranceUserData({
        data: {
          id: this.insuranceData._id,
          insurancePolicyNumber: this.insuranceData.insurancePolicyNumber,
          firstName: this.insuranceData.firstName,
          lastName: this.insuranceData.lastName,
          birthDate: this.insuranceData.birthDate,
          status: 'submitted'
        }
      })

      window.location = '/';

      this.loading = false

    }
  },

  mounted() {
    this.insuranceData = this.me.insurance;

  }
}
</script>

<style>
.theme--light .hp-body-bg {
  background-position: top left, top right;
  /* background-attachment: fixed; */
  background-image: url("../../../assets/background-gradient-left.png"),
  url("../../../assets/background-gradient-right.png");
}
</style>
