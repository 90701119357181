<template>
  <div
    class="hp-body-bg not-logged-in d-flex flex-column"
    style="min-height: 100%"
  >
    <NoUserNavbar :show-back-button="false" />
    <v-container fluid class="my-auto">
      <v-row>
        <v-col cols="12" md="8" lg="6" xl="4" class="mx-auto">
          <v-card class="rounded-card-with-shadow mx-auto">
            <!-- <v-toolbar color="primary" elevation="0" dark>
            <logoSvg />
          </v-toolbar> -->
            <!--          <v-card-title>-->

            <!--            <h4 class="display-1 font-weight-bold primary&#45;&#45;text text-center mt-6">-->
            <!--              {{ $t("Welcome to TalkTime’s Wellness Program !") }}-->
            <!--            </h4>-->
            <!--          </v-card-title>-->
            <v-card-text class="pa-8">
              <p class="body-1">{{ $t("Hi") }} {{ getUserName(me) + "," }}</p>
              <p class="body-1">
                {{
                  $t(
                    "After a thorough review, we regret to inform you that the TalkTime Wellness Program is not covered under your current insurance plan."
                  )
                }}
              </p>
              <p class="body-1">
                {{
                  $t(
                    "If you have any questions or need further assistance, please don't hesitate to reach out to our customer support team."
                  )
                }}
              </p>
            </v-card-text>
            <v-card-actions class="pb-3">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="px-8 py-3"
                block
                elevation="0"
                :loading="loading"
                @click="showEmployeeCodeForm = true"
              >
                {{ $t("Do you have a Corporate Benefits code? Continue here") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-card-actions class="pb-3">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="px-8 py-3"
                block
                elevation="0"
                :loading="loading"
                @click="otherPlans"
              >
                {{ $t("Other out of pocket plans") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-card-actions class="pb-8">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="px-8 py-3"
                block
                elevation="0"
                :loading="loading"
                @click="resubmit"
              >
                {{ $t("Resubmit Insurance program application") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        width="700"
        :fullscreen="$vuetify.breakpoint.sm"
        v-model="showEmployeeCodeForm"
      >
        <verify-employee-code
          v-if="showEmployeeCodeForm"
          @close="showEmployeeCodeForm = false"
          from-insurance
        ></verify-employee-code>
      </v-dialog>
    </v-container>
    <NoUserFooter />
  </div>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import { mapActions } from "vuex";
import NoUserNavbar from "@/components/layout/NoUserNavbar";
import NoUserFooter from "@/components/layout/NoUserFooter";
import VerifyEmployeeCode from "@/components/InitialAssessment/VerifyEmployeeCode.vue";

export default {
  name: "NotConfirmed.vue",
  components: { NoUserNavbar, NoUserFooter, VerifyEmployeeCode },
  mixins: [UserMixin],

  data: () => {
    return {
      loading: false,
      showEmployeeCodeForm: false,
    };
  },

  methods: {
    ...mapActions("user", ["updateInsuranceUserData"]),

    async otherPlans() {
      this.loading = true;

      await this.updateInsuranceUserData({
        data: {
          id: this.me.insurance._id,
          insurancePolicyNumber: this.me.insurance.insurancePolicyNumber,
          firstName: this.me.insurance.firstName,
          lastName: this.me.insurance.lastName,
          birthDate: this.me.insurance.birthDate,
          status: "OutOfPocket",
        },
      });

      await this.$router.push({ name: "TherapistSelection" });

      this.loading = false;
    },

    resubmit() {
      this.$router.push({ name: "InsuranceFormInner" });
      // this.$router.push({name: 'Calendar'})
    },
  },
  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: () => {
        if (
          this.isClient &&
          this.myInsurance &&
          this.myInsurance.status !== "declined"
        ) {
          if (!this.myTherapist) {
            if (this.myInsurance && this.myInsurance.status === "submitted") {
              this.$router.push({ name: "SubmittedByClient" });
            } else if (
              this.myInsurance &&
              this.myInsurance.status === "approved"
            ) {
              this.$router.push({ name: "ConfirmedInsurance" });
            } else {
              this.$router.push({ name: "TherapistSelection" });
            }
          }
        }
      },
    });
  },
};
</script>

<style scoped>
.theme--light .hp-body-bg {
  background-position: top left, top right;
  /* background-attachment: fixed; */
  background-image: url("../../../assets/background-gradient-left.png"),
    url("../../../assets/background-gradient-right.png");
}
</style>
