<template>
  <v-form ref="form" v-model="valid">
    <div class="d-flex flex-column">
      <p :class="innerForm ? 'mx-auto font-weight-bold' : 'caption'">{{ $t("We use this information to verify your insurance benefits with Talktime") }}</p>
      <v-text-field
        :disabled="loading"
        :rules="rules"
        v-model="insuranceData.insurancePolicyNumber" outlined :label="$t('Insurance Policy Number')">
      </v-text-field>
      <v-text-field
        :disabled="loading"
        :rules="rules"
        v-model="insuranceData.firstName" outlined :label="$t('First Name')"></v-text-field>
      <v-text-field
        :disabled="loading"
        :rules="rules"
        v-model="insuranceData.lastName" outlined :label="$t('Last Name')"></v-text-field>
      <v-menu
        ref="birthDateMenuRef"
        v-model="birthDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <!--                  <v-text-field :readonly="!editable" v-model="insuranceData.birthDate" :rules="dobRules"-->
          <v-text-field
            :disabled="loading"
            outlined
            :rules="rules"
            v-model="insuranceData.birthDate"
            :label="$t('DateOfBirth')"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          :locale="$vuetify.rtl ? 'ar' : 'en'"

          no-title
          v-model="insuranceData.birthDate"
          :show-current="false"
          :active-picker.sync="dateActivePicker"
          :max="maxBirthDate"
          min="1950-01-01"
          @change="saveBirthDate"
        ></v-date-picker>
      </v-menu>

      <v-btn
        :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        color="primary"
        class="mx-auto align-self-start verify-button "
        :loading="loading"

        @click="continueInsurance"
      >
        {{ $t("Continue") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'InsuranceForm',

  props: {
    insuranceData: {
      type: Object,
      default: () => {}
    },

    loading: {
      type: Boolean,
      default: false,
    }
  },

  data: () => {
    return {
      birthDateMenu: false,
      dateActivePicker: null,
      valid: false,
    }
  },

  computed: {
    rules() {
      return [
        (v) => !!v || this.$t("Required"),
      ]
    },
    innerForm() {
      return this.$route.query?.next !== "corporate-services";

    },

    maxBirthDate() {
      let today = new Date();
      today.setFullYear(today.getFullYear() - 18);
      return today.toISOString().substring(0, 10);
    },
  },

  watch: {
    birthDateMenu(val) {
      val && setTimeout(() => (this.dateActivePicker = "YEAR"));
    },
  },

  methods: {
    saveBirthDate(date) {
      this.$refs.birthDateMenuRef.save(date);
    },

    continueInsurance() {
      if (this.$refs.form.validate()) {
        this.$emit("continueInsurance");
      }


    }
  }
}

</script>
