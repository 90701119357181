<template>
  <v-card class="rounded-lg">
    <v-btn @click="$emit('close')" elevation="2" icon class="close-icon pa-0">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <template v-if="!codeValid">
      <v-card-title>
        <div>{{ $t("Verify your Corporate Benefits code") }}</div>
      </v-card-title>
      <v-card-text class="pa-8">
        <v-text-field
          outlined
          solo
          flat
          dense
          v-model="promoCode"
          class="rounded-15px"
          :label="$t('Enter code here')"
          :error-messages="codeNotValidMessages"
          :success="codeValid"
          :readonly="codeValid"
          :loading="loading"
        />
      </v-card-text>
      <v-card-actions class="pb-6">
        <v-spacer></v-spacer>
        <v-btn
          :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
          color="primary"
          class="ml-3 py-3 align-self-start"
          :loading="loading"
          :disabled="codeValid"
          @click="verifyCode"
        >
          {{ $t("Verify") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </template>
    <template v-else>
      <v-card-text>
        <benefit-page
          v-if="showBenefitPage && codeValid"
          @close="$emit('close')"
          @success="onCloseBenfitInformation"
          :loading="loading"
        ></benefit-page>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import BenefitPage from "@/components/InitialAssessment/BenefitPage.vue";
export default {
  components: { BenefitPage },
  props: {
    fromInsurance: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredEmail: false,
      loading: false,
      codeNotValidMessages: [],
      codeValid: false,
      promoCode: "",
      showBenefitPage: false,
    };
  },
  computed: {
    ...mapGetters("therapist", ["demoTherapist"]),
  },
  methods: {
    ...mapActions("corporateProgram", ["checkPromotionCode"]),
    ...mapActions("user", ["addPromoCodeToUser", "getMe"]),
    ...mapActions("therapist", ["addTherapistToUserCompany"]),
    ...mapMutations("preRegister", [
      "setPromoCodeData",
      "setSponserType",
      "setTherapyType",
    ]),
    async onCloseBenfitInformation() {
      this.codeValid = true;
      this.loading = true;
      if (this.fromInsurance) {
        await this.getMe();
        await this.$router.push({ name: "TherapistSelection" });
        this.loading = false;
      } else {
        this.addTherapistToUserCompany({
          id: this.demoTherapist._id,
        }).then(() => {
          this.$nextTick(async () => {
            await this.getMe();
            this.loading = false;
            await this.$router.push({ name: "WelcomeBack" });
          });
        });
      }
    },
    async verifyCode() {
      this.loading = true;
      try {
        let res = await this.checkPromotionCode({ code: this.promoCode });

        this.codeNotValidMessages = [];

        this.setSponserType("myCompany");
        this.setPromoCodeData(res);
        this.setTherapyType("Individual Therapy");
        await this.addPromoCodeToUser({ promoCode: this.promoCode });

        this.showBenefitPage = true;
        this.codeValid = true;
      } catch (e) {
        // TODO: Sahak translate
        this.codeNotValidMessages = ["Code Not Valid"];
      }

      this.loading = false;

      // setTimeout(() => {
      //   this.loading = false;
      //   this.requiredEmail = true;
      //   this.codeValid = true;
      // }, 1000)
    },
  },
};
</script>
